import * as React from 'react';

import Button from '@rotaryintl/harris-button';

import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';
import Title from '@components/Title';
import DataSelectorClubOptions from '@presenters/web/components/DataSelector/DataSelectorClubOptions';
import DataSelectorDistrictOptions from '@presenters/web/components/DataSelector/DataSelectorDistrictOptions';

import { useErrorHandling } from '@use-cases/notifications';

import {
  useFetchBIReportAccess,
  useFetchReportOrgOptions,
} from '@repositories/reports';

import { FEATURE_SHAREPOINT, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';
import { getQueryParams } from '@utils/query-params';

import { useTranslation } from '@external/react-i18next';

const DataSelector = () => {
  const { t } = useTranslation();
  const isSharepointEnabled = isEnabled(FEATURE_SHAREPOINT);

  const { oktaId, reportTechnicalName, dataSelector } = getQueryParams();

  const {
    data: dataBIReportAccess,
    loading: loadingBIReportAccess,
  } = useFetchBIReportAccess(oktaId as string, null, null);
  const { data, error, loading } = useFetchReportOrgOptions(
    dataSelector as string,
    oktaId as string
  );

  useErrorHandling(
    t(
      'data-selector.org-options-error',
      'An error occurred while fetching Organizations for selecting.'
    ),
    error !== undefined,
    'data-selector.error'
  );

  if (!isSharepointEnabled) {
    return <NotFound />;
  }

  if (loading || !data || loadingBIReportAccess || !dataBIReportAccess) {
    return <Loading />;
  }

  if (!dataBIReportAccess.access) {
    return (
      <OneColumn className="mb-20">
        <AccessDenied />
      </OneColumn>
    );
  }

  if (data && data.options.length === 1) {
    if (dataSelector === 'club' && data.options[0].matchType === 'club') {
      const { clubId } = data.options[0];
      localizedNavigate(
        `/club-and-district-reports/${oktaId}/${reportTechnicalName}/club?clubId=${clubId}`
      );
    }

    if (
      dataSelector === 'district' &&
      data.options[0].matchType === 'district'
    ) {
      const { districtId } = data.options[0];
      localizedNavigate(
        `/club-and-district-reports/${oktaId}/${reportTechnicalName}/district?districtId=${districtId}`
      );
    }
  }

  return (
    <OneColumn className="mb-20">
      <Button
        clickHandler={() =>
          localizedNavigate('/membership-and-foundation-reports')
        }
        icon="fas-angle-left"
        size="md"
        variant="text"
        className="mt-6"
      >
        {t('back-link-text', 'Back')}
      </Button>
      <Title>{t('data-selector.title', 'Verify & Continue')}</Title>
      <div>
        {t(
          'data-selector.description',
          'You have access to multiple districts, clubs or roles. Please select the appropriate option to continue. '
        )}
      </div>
      {dataSelector === 'club' && (
        <DataSelectorClubOptions
          oktaId={oktaId as string}
          reportTechnicalName={reportTechnicalName as string}
          options={data.options}
        />
      )}
      {dataSelector === 'district' && (
        <DataSelectorDistrictOptions
          oktaId={oktaId as string}
          reportTechnicalName={reportTechnicalName as string}
          options={data.options}
        />
      )}
    </OneColumn>
  );
};

export default DataSelector;
