import React from 'react';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from '@components/Button';

import { CustomLabel } from './CustomLabel';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { GetBiReportOrgOptionsQuery } from '@typings/operations';

interface Props {
  oktaId: string;
  reportTechnicalName: string;
  options: GetBiReportOrgOptionsQuery['options'];
}

const validationSchema = Yup.object().shape({
  selectedOption: Yup.string().required('Please select an option'),
});

interface FormValues {
  selectedOption: string;
}

const DataSelectorDistrictOptions: React.FC<Props> = ({
  oktaId,
  reportTechnicalName,
  options,
}) => {
  const { t } = useTranslation();

  const handleSubmit = async (values: FormValues) => {
    const { selectedOption } = values;

    const selectedDistrictId = selectedOption.split('-')[0];

    localizedNavigate(
      `/club-and-district-reports/${oktaId}/${reportTechnicalName}/district?districtId=${selectedDistrictId}`
    );
  };

  return (
    <Formik
      initialValues={{ selectedOption: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <hr className="border-t-0 border-b border-gray-300 w-full mt-2 desktop:mt-2 mb-2" />

          {options.map((option, index) => {
            const uniqueId = `${option.roleName.replace(/\s+/g, '-')}-${
              option.districtId
            }`;
            const uniqueValue = `${option.districtId}-${index}`;
            const fieldId = `radio-${uniqueId}`;
            const isSelected = values.selectedOption === uniqueValue;

            return (
              <div key={uniqueId}>
                <div className="flex flex-col py-6">
                  <label
                    className="flex w-full cursor-pointer relative"
                    htmlFor={fieldId}
                  >
                    <Field
                      id={fieldId}
                      type="radio"
                      name="selectedOption"
                      value={uniqueValue}
                      checked={isSelected}
                      onChange={() => {
                        setFieldValue('selectedOption', uniqueValue);
                        setFieldValue('type', ''); // Reset the dropdown if any
                      }}
                      className="w-0"
                    />
                    <div className="w-full">
                      <div className="RadioLabel">
                        <div className="-mt-[3px] pl-[5px]">
                          <CustomLabel
                            role={option.roleName}
                            club={option.clubName}
                            district={option.districtId}
                            startDate={option.termStartDate}
                            endDate={option.termEndDate}
                          />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
                <hr className="border-t-0 border-b border-gray-300 w-full mt-2 desktop:mt-2 mb-2" />
              </div>
            );
          })}

          <Button
            type="submit"
            className="mt-12 p-4 rounded-md text-white disabled:bg-gray-300 bg-bright-blue-600"
            disabled={values.selectedOption === ''}
          >
            {t('continue', 'Continue')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default DataSelectorDistrictOptions;
