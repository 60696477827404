import React from 'react';

import { getFormattedDate } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

interface CustomLabelProps {
  role: string;
  club: string;
  district: string;
  startDate: string;
  endDate: string;
}
export const CustomLabel: React.FC<CustomLabelProps> = ({
  role,
  club,
  district,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();

  const startDateText = t('data-selector.verify-continue-start', 'Start');
  const formattedStartDate = getFormattedDate(startDate, 'DD MMMM YYYY');
  const startDateLabel = `${startDateText}: ${formattedStartDate}`;

  const endDateText = endDate
    ? t('data-selector.verify-continue-end', 'End')
    : '';
  const formattedEndDate = endDate
    ? getFormattedDate(endDate, 'DD MMMM YYYY')
    : '';
  const endDateLabel =
    endDateText && formattedEndDate
      ? `${endDateText}: ${formattedEndDate}`
      : '';

  return (
    <div className="flex flex-row w-full gap-17 text-xs">
      <div className="basis-1/3 m-0">
        <div className="font-bold m-0">{getClubRoleTValue(t, role)}</div>
        <div>{club}</div>
      </div>
      <div className=" basis-2/3">
        <div>
          {t('data-selector.verify-continue-district', 'District')} {district}
        </div>
        <div>{`${startDateLabel} ${endDateLabel}`}</div>
      </div>
    </div>
  );
};
